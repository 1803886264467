












import Vue from 'vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import SleepTrackForm from '@/views/tools/sleep/components/track/sleep-track-form.vue';

export default Vue.extend({
  name: 'edit-sleep-track',
  components: {
    SleepTrackForm,
  },
  props: {
    sleep: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadedSleep: null as ISleepTracker | null,
    };
  },
  computed: {
    storeSleepTrack(): ISleepTracker[] {
      return this.$store.getters['sleep/sleepTrack'];
    },
    hasSleepTrack(): Boolean {
      return !!this.storeSleepTrack?.length;
    },
  },
  mounted() {
    this.sleep.then(res => {
      this.loadedSleep = res as ISleepTracker;
    });
  },
  methods: {
    goToSleepTrackList() {
      this.$router.replace({ name: 'sleep-track-list' });
    },
  },
});
